.screenplay-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Set the container height to fill the viewport */
    width: 60vh;
    flex: auto;
  }
  
.screenplay-info{
    width: 80%;
}
  
  .screenplay {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Set the height of the parent div */
    flex: 1; /* Allow the sidebar to expand and fill the available space */
    overflow-y: hidden; /* Enable vertical scrolling if needed */
    padding-bottom: 20px; /* Add some padding to accommodate the fixed profile button */
    position: relative; /* Add position relative to the sidebar */
  }

  @media screen and (max-width: 767px) {
    .screenplay {
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%; /* Set the height of the parent div */
      width: 100vw;
      flex: 1; /* Allow the sidebar to expand and fill the available space */
      overflow-y: hidden; /* Enable vertical scrolling if needed */
      padding-bottom: 20px; /* Add some padding to accommodate the fixed profile button */
      position: relative; /* Add position relative to the sidebar */
    }
  }
  
  .screenplay-header {
    flex-direction: row;
    display: flex;
  }
  
  .loading-wheel {
    display: inline-block;
    width: 400px; /* Adjust the width and height as needed */
    height: 150px;
    background: url('../public/carousel4.gif') no-repeat center;
    background-size: cover;
  }

  .download-pdf-button {
    font-family: 'UnionMade', sans-serif;
    margin-right: 5px;
    margin-left: 50px;
    padding: 10px 10px;
    border: none;
    background-color: #007479; /* Deep Teal */
    color: #F2DC9F; /* Muted Yellow */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    height: fit-content;
    white-space: nowrap;
  }

  .under-hamburger{
    width: 30px;
    margin-right: 10px;
    padding-top: 43px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 768px) {
    .under-hamburger {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .download-pdf-button {
      display: none;
    }
  }

  .download-pdf-icon {
    font-family: 'UnionMade', sans-serif;
    border: none;
    background-color: #007479; /* Deep Teal */
    color: #F2DC9F; /* Muted Yellow */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 30px;
    height: 30px;
  }
